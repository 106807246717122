import {
  Input,
  Select,
  Modal,
  Space,
  Collapse,
  Slider,
  DatePicker,
  Cascader,
  InputNumber,
  Avatar,
  Form as FormAnt,
  Radio,
  Button as AntButton,
  Mentions
} from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import GridLayout from "react-grid-layout";
import { useReactToPrint } from 'react-to-print';
import {
  Container,
  Button,
  Form,
  Image,
  Col,
  Row,
  Badge,
  Table,
  Tooltip,
  Card
} from "react-bootstrap";
import { Activity, Bookmark, DoorOpen, Eraser, GearFill, Info, InfoCircle, NutFill, People, Plus, Printer, PrinterFill, TelephoneMinus } from "react-bootstrap-icons";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import { GridLoader } from "react-spinners";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipRehart,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { CustomModalV2 } from "../Components/CustomModal";
import {
  sendupdateTresholdCommand,
  sendupdateTresholdCommandGeneric,
} from "../Components/Floor";
import {
  GenericFormElement,
  GenericSelectForm,
} from "../Components/GenericFormController";
import Header from "../Components/Header";
import HeaderTitle from "../Components/HeaderTitle";
import httpService from "../services/httpService";
import {
  errorMessage,
  LoadingComponents,
  successMessage,
} from "../services/utilities";
import { UpdatePersonV3 } from "./People";
import { useRef } from "react";
import { CloseCircleFilled } from "@ant-design/icons";

const { SHOW_CHILD } = Cascader;

const { RangePicker } = DatePicker;

const { Option } = Select;
const { Panel } = Collapse;

export const SensorInformationDisplay = (props) => {

  return (<><Card onDoubleClick={e => props.onDouble()} style={{ textAlign: "center" }}>
    <>{props?.label}</>
    <div>{props.children}</div>



  </Card></>)
}

export const IZoneAdvanceSettings = (props) => {
  const [notifications, setnotifications] = useState([
    "TEMPERATURE 1W HIGH",
    "PDU 1 OFF",
    "PDU 2 OFF",
    "TEMPERATURE 1W LOW",
    "TEMPERATURE 2W HIGH",
    "TIMEOUT",
    "OFFLINE",
    "HUMIDITY 1 HIGH",
    "HUMIDITY 1 LOW",
    "TARGET OFFLINE",
    "ACTIVE POWER HIGH",
    "ACTIVE POWER LOW",
    "AC 1 OFF",
    "DOOR 1 OPENED",
    "DOOR 2 OPENED",
    "IN1 REMIO PROBE 1 HIGH",
    "IN1 REMIO PROBE 2 HIGH",
    "IN2 REMIO PROBE 1 HIGH",
    "IN2 REMIO PROBE 2 HIGH",
    "IN3 REMIO PROBE 1 HIGH",
    "IN3 REMIO PROBE 2 HIGH",
    "IN4 REMIO PROBE 1 HIGH",
    "IN4 REMIO PROBE 2 HIGH",
    "IN1 REMIO PROBE 3 HIGH",
    "IN1 REMIO PROBE 4 HIGH",
    "IN2 REMIO PROBE 3 HIGH",
    "IN2 REMIO PROBE 4 HIGH",
    "IN3 REMIO PROBE 3 HIGH",
    "IN3 REMIO PROBE 4 HIGH",
    "IN4 REMIO PROBE 3 HIGH",
    "IN4 REMIO PROBE 4 HIGH",

  ]);

  const [sttingsToDisplay, setsttingsToDisplay] = useState([
    ...props?.selectediZone?.alarms,
  ]);

  const [sensorInfomation, setsensorInfomation] = useState([
    ...(props?.izoneAll?.sensors || []),
  ]);

  const [commands, setcommands] = useState([
    ...(props?.izoneAll?.commands || []),
  ]);

  const [accessGroup, setaccessGroup] = useState([]);

  const [emails, setemails] = useState([]);

  const [availableDevices, setavailableDevices] = useState([]);

  const [
    commandsToExecuteOnNotifications,
    setcommandsToExecuteOnNotifications,
  ] = useState([]);

  const udpateIteminfo = (index, path, value) => {
    let temp = sttingsToDisplay;
    temp[index] = { ...temp[index], [path]: value };
    setsttingsToDisplay(temp);
  };

  const udpateIteminfov2 = (data, index, path, value, setdata) => {
    let temp = data;
    temp[index] = { ...temp[index], [path]: value };
    setdata(temp);
  };

  const removeIteminfov2 = (data, index, setdata) => {
    let temp = data;
    temp.splice(index, 1);
    setdata([...temp]);
  };

  const udpateIteminfov3 = (
    data,
    index,
    path,
    value,
    setdata,
    index2,
    subpath
  ) => {
    let temp = [...data];
    //console.log(temp[index][subpath]);
    temp[index][subpath][index2] = {
      ...temp[index][subpath][index2],
      [path]: value,
    };
    //console.log(temp[index][subpath][index2]);
    setdata(temp);
  };

  const removeIteminfov3 = (data, index, setdata, subpath, index2) => {
    let temp = [...data];
    temp[index][subpath].splice(index2, 1);
    setdata(temp);
  };

  const updateNotificationInformation = () => {
    udpateSensorsCommands();

    httpService
      .put(`/v1/izone/${props.selectediZone?._id}/alarms`, {
        alarms: sttingsToDisplay,
      })
      .then((res) => {
        successMessage(`Settings  udpated!`);
      })
      .catch((err) => {
        errorMessage(`Notification settings failed to udpated!`);
      });
  };

  const udpateSensorsCommands = () => {
    if (props?.izoneAll?.commands === commands) {
      successMessage(`Nothing has changed thank you !`);
      return;
    }
    httpService
      .put(`/v1/izone/${props.selectediZone?._id}/sensors`, {
        sensors: sensorInfomation,
        commands: commands,
      })
      .then((res) => { })
      .catch((err) => {
        errorMessage(`advacnce settings failed to udpated!`);
      });
  };

  const loadEmails = async () => {
    httpService
      .get("/v1/accessgroup/lite")
      .then((res) => {
        const { data } = res;
        setaccessGroup([...data]);
      })
      .catch((eror) => {
        setaccessGroup([]);
      });

    httpService.get(`/v1/people?type=emails`).then((res) => {
      const { data } = res;
      setemails([...data]);
    });

    httpService
      .get(`/v1/building/availabledevices`)
      .then((res) => {
        const { data } = res;
        setavailableDevices([...data]);
      })
      .catch((error) => { });
  };

  const buildCommandsToExecute = () => {
    var mycomamnds = commands?.map((mycommandsmap) => {
      var tempmap = {
        label: mycommandsmap.name,
        value: mycommandsmap.name,
        children: mycommandsmap?.commands?.map(
          (commandsmap) =>
          ({
            label: commandsmap.label,
            value: `${commandsmap.label},${commandsmap.command}`,
          } || [])
        ),
      };
      return tempmap;
    });

    setcommandsToExecuteOnNotifications(mycomamnds);
  };

  useEffect(() => {
    loadEmails();
    buildCommandsToExecute();
    return () => { };
  }, [props?.name]);

  return (
    <>
      <Modal
        forceRender={true}
        width={1300}
        okType="success"
        title={`Advance iZone Settings ${props.selectediZone?.name ?? ""}`}
        visible={props.show}
        onOk={() => {
          updateNotificationInformation();
        }}
        onCancel={() => props.onClose()}
      >
        <Collapse>
          <Panel header="Notification Settings" key="1">
            <Button
              onClick={(e) => {
                setsttingsToDisplay((old) => [
                  ...old,
                  {
                    name: "",
                    deviceId: "",
                    label: "",
                    status: "clear",
                    type: "red",
                  },
                ]);
              }}
              size="sm"
              style={{ margin: 10 }}
              variant="dark"
            >
              +
            </Button>{" "}
            Add Alarms
            <Table responsive>
              <thead>
                <th>Device</th>
                <th>Notification</th>
                <th>Alarm</th>
                <th>Clear</th>
                <th>Notificants</th>
                <th>Severity</th>
                <th>Extra Information</th>
                <th></th>
              </thead>
              <tbody>
                {sttingsToDisplay &&
                  sttingsToDisplay.map((mapalarmSettings, index) => (
                    <>
                      <tr style={{ margin: 5 }}>
                        <td>
                          <Select
                            defaultValue={mapalarmSettings["deviceId"]}
                            onChange={(e) => {
                              udpateIteminfo(index, "deviceId", e);
                            }}
                            style={{ width: 200 }}
                          >
                            {availableDevices?.map((mapdevices) => (
                              <Option value={mapdevices.value}>
                                {mapdevices.label}{" "}
                              </Option>
                            ))}
                          </Select>

                          {/* <Input
                            style={{ width: 150 }}
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "deviceId",
                                e.currentTarget.value
                              );
                            }}
                            defaultValue={mapalarmSettings.deviceId}
                            placeholder="Device Key"
                          /> */}
                        </td>
                        <td>
                          <Select
                            onSelect={(e) => {
                              udpateIteminfo(index, "name", e);
                            }}
                            style={{ width: 200 }}
                            defaultValue={mapalarmSettings.name}
                          >
                            {notifications.map((mapno) => (
                              <>
                                <Option value={mapno}>{mapno}</Option>
                              </>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <div style={{ fontWeight: "bold" }}>Message</div>
                          <Input.TextArea
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "label",
                                e.currentTarget.value
                              );
                            }}
                            defaultValue={mapalarmSettings.label}
                            placeholder="Alarm message"
                            rows={2}
                          />
                          <div style={{ fontWeight: "bold" }}>Actions</div>
                          <Cascader
                            style={{ width: "100%" }}
                            options={commandsToExecuteOnNotifications}
                            onChange={(e) => {
                              //console.log(`Commands to execute ... `, e);
                              udpateIteminfo(index, "commandToExecute", e);
                            }}
                            multiple
                            maxTagCount="responsive"
                            defaultValue={mapalarmSettings?.commandToExecute}
                            showCheckedStrategy={SHOW_CHILD}
                          />
                        </td>
                        <td>
                          <div style={{ fontWeight: "bold" }}>Message</div>
                          <Input.TextArea
                            rows={2}
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "labelClear",
                                e.currentTarget.value
                              );
                            }}
                            defaultValue={mapalarmSettings?.labelClear}
                            placeholder="Clear Message"
                          />
                          <div style={{ fontWeight: "bold" }}>Actions</div>
                          <Cascader
                            style={{ width: "100%" }}
                            options={commandsToExecuteOnNotifications}
                            onChange={(e) => {
                              //console.log(`Commands to execute ... `, e);
                              udpateIteminfo(index, "commandToExecuteClear", e);
                            }}
                            multiple
                            maxTagCount="responsive"
                            defaultValue={
                              mapalarmSettings?.commandToExecuteClear
                            }
                            showCheckedStrategy={SHOW_CHILD}
                          />
                        </td>
                        <td>
                          <Select
                            value={mapalarmSettings["emails"]}
                            onChange={(e) => {
                              udpateIteminfo(index, "emails", e);
                            }}
                            style={{ width: 200 }}
                            mode="tags"
                          >
                            {emails?.map((mapemails) => (
                              <Option value={mapemails.username}>
                                {mapemails.username}{" "}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Select
                            value={mapalarmSettings["type"]}
                            onChange={(e) => {
                              udpateIteminfo(index, "type", e);
                            }}
                            style={{ width: 100 }}
                          >
                            <Option value="red">
                              <Badge bg="danger">Critical</Badge>{" "}
                            </Option>
                            <Option value="orange">
                              <Badge bg="warning">Warning</Badge>
                            </Option>
                            <Option value="yellow">
                              <Badge bg="success">Info</Badge>{" "}
                            </Option>
                          </Select>
                        </td>
                        <td>
                          <Input.TextArea
                            rows={2}
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "extraInformation",
                                e.currentTarget.value
                              );
                            }}
                            defaultValue={mapalarmSettings?.extraInformation}
                            placeholder="Extra Information"
                          />
                        </td>
                        <td>
                          <Button
                            onClick={(e) => {
                              removeIteminfov2(
                                sttingsToDisplay,
                                index,
                                setsttingsToDisplay
                              );
                            }}
                            size="sm"
                            style={{ margin: 10 }}
                            variant="danger"
                          >
                            -
                          </Button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </Panel>
          <Panel showArrow={true} header="Sensor Settings" key="2">
            <Button
              onClick={(e) => {
                setsensorInfomation((old) => [
                  ...old,
                  {
                    name: "",
                    deviceId: "",
                    label: "",
                    type: "Number",
                    symbole: "",
                    value: "0",
                  },
                ]);
              }}
              size="sm"
              style={{ margin: 10 }}
              variant="dark"
            >
              +
            </Button>
            <Table responsive>
              <thead>
                <th>Name</th>
                <th>Label</th>
                <th>Symbole</th>
                <th>Type</th>
                <th>Deivice Id</th>
                <th></th>
              </thead>
              <tbody>
                {sensorInfomation?.map((mapsensor, index2) => (
                  <tr>
                    <td>
                      <Input
                        style={{ width: 150 }}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "name",
                            e.currentTarget.value,
                            setsensorInfomation
                          );
                        }}
                        defaultValue={mapsensor.name}
                        placeholder="Sensor Name"
                      />
                    </td>
                    <td>
                      <Input
                        style={{ width: 250 }}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "label",
                            e.currentTarget.value,
                            setsensorInfomation
                          );
                        }}
                        defaultValue={mapsensor.label}
                        placeholder="label"
                      />
                    </td>
                    <td>
                      <Input
                        style={{ width: 50 }}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "symbole",
                            e.currentTarget.value,
                            setsensorInfomation
                          );
                        }}
                        defaultValue={mapsensor.symbole}
                        placeholder="symbole"
                      />
                    </td>
                    <td>
                      <Select
                        value={mapsensor["type"]}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "type",
                            e,
                            setsensorInfomation
                          );
                        }}
                        style={{ width: 100 }}
                      >
                        <Option value="Number">Number </Option>
                        <Option value="Status">Status</Option>
                      </Select>

                      {mapsensor["type"] === "Status" && (
                        <>
                          <Button
                            onClick={(e) => {
                              let temp = [...sensorInfomation];
                              //console.log(temp[index2]);
                              if (!temp[index2]["valueLables"]) {
                                temp[index2]["valueLables"] = [
                                  { value: "0", label: "", color: "success" },
                                ];
                              } else {
                                temp[index2]["valueLables"] = [
                                  ...temp[index2]["valueLables"],
                                  { value: "0", label: "", color: "success" },
                                ];
                              }

                              setsensorInfomation(temp);
                            }}
                            size="sm"
                            style={{ margin: 10 }}
                            variant="dark"
                          >
                            +
                          </Button>
                          <Table responsive>
                            <thead>
                              <th>Device Key</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Commands</th>
                              <th></th>
                            </thead>
                            <tbody>
                              {mapsensor?.valueLables?.map(
                                (mapsensorlabels, mapsensorlabelsindex) => (
                                  <>
                                    <tr>
                                      <td>
                                        {" "}
                                        <Input
                                          style={{ width: 70, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              sensorInfomation,
                                              index2,
                                              "value",
                                              e.currentTarget.value,
                                              setsensorInfomation,
                                              mapsensorlabelsindex,
                                              "valueLables"
                                            );
                                          }}
                                          defaultValue={mapsensorlabels.value}
                                          placeholder="value"
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <Input
                                          style={{ width: 100, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              sensorInfomation,
                                              index2,
                                              "label",
                                              e.currentTarget.value,
                                              setsensorInfomation,
                                              mapsensorlabelsindex,
                                              "valueLables"
                                            );
                                          }}
                                          defaultValue={mapsensorlabels.label}
                                          placeholder="label"
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          value={mapsensorlabels["color"]}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              sensorInfomation,
                                              index2,
                                              "color",
                                              e,
                                              setsensorInfomation,
                                              mapsensorlabelsindex,
                                              "valueLables"
                                            );
                                          }}
                                          style={{ width: 100, margin: 5 }}
                                        >
                                          <Option value="danger">
                                            <Badge bg="danger">danger</Badge>{" "}
                                          </Option>
                                          <Option value="warning">
                                            <Badge bg="warning">warning</Badge>
                                          </Option>
                                          <Option value="success">
                                            <Badge bg="success">success</Badge>{" "}
                                          </Option>
                                        </Select>
                                      </td>

                                      <td>
                                        <Button
                                          onClick={(e) => {
                                            removeIteminfov3(
                                              sensorInfomation,
                                              index2,
                                              setsensorInfomation,
                                              "valueLables",
                                              mapsensorlabelsindex
                                            );
                                          }}
                                          size="sm"
                                          style={{ margin: 5 }}
                                          variant="danger"
                                        >
                                          -
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}
                            </tbody>
                          </Table>
                        </>
                      )}
                      {mapsensor["type"] === "Number" && (
                        <>
                          <Table responsive>
                            <thead>
                              <th>Max</th>
                              <th>Min</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Input
                                    style={{ width: 50 }}
                                    onChange={(e) => {
                                      udpateIteminfov2(
                                        sensorInfomation,
                                        index2,
                                        "max",
                                        e.currentTarget.value,
                                        setsensorInfomation
                                      );
                                    }}
                                    defaultValue={mapsensor.max}
                                    placeholder="max"
                                  />
                                </td>
                                <td>
                                  <Input
                                    style={{ width: 50 }}
                                    onChange={(e) => {
                                      udpateIteminfov2(
                                        sensorInfomation,
                                        index2,
                                        "min",
                                        e.currentTarget.value,
                                        setsensorInfomation
                                      );
                                    }}
                                    defaultValue={mapsensor.min}
                                    placeholder="min"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      )}
                    </td>
                    <td>
                      <Select
                        defaultValue={mapsensor["deviceId"]}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "deviceId",
                            e,
                            setsensorInfomation
                          );
                        }}
                        style={{ width: 200 }}
                      >
                        {availableDevices?.map((mapdevices) => (
                          <Option value={mapdevices.value}>
                            {mapdevices.label}{" "}
                          </Option>
                        ))}
                      </Select>
                    </td>

                    <td>
                      <Button
                        onClick={(e) => {
                          removeIteminfov2(
                            sensorInfomation,
                            index2,
                            setsensorInfomation
                          );
                        }}
                        size="sm"
                        style={{ margin: 10 }}
                        variant="danger"
                      >
                        -
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
          <Panel showArrow={true} header="iZone Commands" key="3">
            <Button
              onClick={(e) => {
                setcommands((old) => [
                  ...old,
                  {
                    name: "",
                    deviceId: "",
                    commands: [],
                  },
                ]);
              }}
              size="sm"
              style={{ margin: 10 }}
              variant="dark"
            >
              +
            </Button>
            <Table responsive>
              <thead>
                <th>Device Key</th>
                <th>Name</th>
                <th>Type</th>
                <th>Commands</th>
                <th>Access Group</th>
                <th></th>
              </thead>
              <tbody>
                {commands?.map((mapcommand, index2) => (
                  <tr>
                    <td>
                      <Select
                        defaultValue={mapcommand["deviceId"]}
                        onChange={(e) => {
                          udpateIteminfov2(
                            commands,
                            index2,
                            "deviceId",
                            e,
                            setcommands
                          );
                        }}
                        style={{ width: 200 }}
                      >
                        {availableDevices?.map((mapdevices) => (
                          <Option value={mapdevices.value}>
                            {mapdevices.label}{" "}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <Input
                        style={{ width: 200 }}
                        onChange={(e) => {
                          udpateIteminfov2(
                            commands,
                            index2,
                            "name",
                            e.currentTarget.value,
                            setcommands
                          );
                        }}
                        defaultValue={mapcommand.name}
                        placeholder="Name"
                      />
                    </td>
                    <td>
                      <Select
                        defaultValue={mapcommand?.type}
                        onChange={(e) => {
                          udpateIteminfov2(
                            commands,
                            index2,
                            "type",
                            e,
                            setcommands
                          );
                        }}
                        style={{ width: 100, margin: 5 }}
                      >
                        <Option value="Standard">
                          <Badge bg="success">Standard</Badge>{" "}
                        </Option>
                        <Option value="Legacy">
                          <Badge bg="warning">Legacy</Badge>
                        </Option>
                      </Select>
                    </td>
                    <td>
                      <Button
                        onClick={(e) => {
                          let temp = commands;
                          temp[index2]["commands"] = [
                            ...temp[index2]["commands"],
                            { label: "", command: "", value: 0 },
                          ];
                          setcommands(temp);
                        }}
                        size="sm"
                        style={{ margin: 10 }}
                        variant="dark"
                      >
                        +
                      </Button>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Mode</th>
                            <th>Name</th>
                            <th>Command</th>
                            <th>Value</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {mapcommand?.commands?.map(
                            (mapcomamndoprions, indexmoreoptionscommand) => (
                              <>
                                <tr>
                                <td>
                                    {" "}

                                    <Select
                                    value={mapcomamndoprions?.mode}
                                    onChange={(e) => {
                                      udpateIteminfov3(
                                        commands,
                                        index2,
                                        "Mode",
                                        e.currentTarget.value,
                                        setcommands,
                                        indexmoreoptionscommand,
                                        "commands"
                                      );
                                    }}
                                    style={{ width: 200 }}
                                  ></Select>
                                    
                                  </td>
                                  <td>
                                    {" "}
                                    <Input
                                      style={{ width: 70, margin: 5 }}
                                      onChange={(e) => {
                                        udpateIteminfov3(
                                          commands,
                                          index2,
                                          "label",
                                          e.currentTarget.value,
                                          setcommands,
                                          indexmoreoptionscommand,
                                          "commands"
                                        );
                                      }}
                                      defaultValue={mapcomamndoprions.label}
                                      placeholder="label"
                                    />
                                  </td>

                                  <td>
                                    {" "}
                                    <Input
                                      style={{ width: 130, margin: 5 }}
                                      onChange={(e) => {
                                        udpateIteminfov3(
                                          commands,
                                          index2,
                                          "command",
                                          e.currentTarget.value,
                                          setcommands,
                                          indexmoreoptionscommand,
                                          "commands"
                                        );
                                      }}
                                      defaultValue={mapcomamndoprions.command}
                                      placeholder="command"
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <Input
                                      style={{ width: 50, margin: 5 }}
                                      onChange={(e) => {
                                        udpateIteminfov3(
                                          commands,
                                          index2,
                                          "value",
                                          e.currentTarget.value.toString(),
                                          setcommands,
                                          indexmoreoptionscommand,
                                          "commands"
                                        );
                                      }}
                                      defaultValue={mapcomamndoprions?.value}
                                      placeholder="value"
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      onClick={(e) => {
                                        removeIteminfov3(
                                          commands,
                                          index2,
                                          setcommands,
                                          "commands",
                                          indexmoreoptionscommand
                                        );
                                      }}
                                      size="sm"
                                      style={{ margin: 5 }}
                                      variant="danger"
                                    >
                                      -
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            )
                          )}
                        </tbody>
                      </Table>
                    </td>
                    <td>
                      <Select
                        mode="tags"
                        value={mapcommand["accessGroup"]}
                        onChange={(e) => {
                          udpateIteminfov2(
                            commands,
                            index2,
                            "accessGroup",
                            e,
                            setcommands
                          );
                        }}
                        style={{ width: 200 }}
                      >
                        {accessGroup?.map((mapdevices) => (
                          <Option value={mapdevices._id}>
                            {mapdevices.name}{" "}
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <Button
                        onClick={(e) => {
                          removeIteminfov2(commands, index2, setcommands);
                        }}
                        size="sm"
                        style={{ margin: 10 }}
                        variant="danger"
                      >
                        -
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
};


const SensorsSelector = (props) => {
  const [availableSensors, setavailableSensors] = useState();

  function loadDeviceTelemetriesKeys() {
    httpService.get(`/v1/izone/device/${props.deviceId}/telemetrieskeys`).then(response => {
      const { data } = response;
      //console.log(data);
      setavailableSensors([...data])
    }).catch(error => { });
  }

  useEffect(() => {

    // if(props.deviceId)
    // loadDeviceTelemetriesKeys();
    // return () => {

    // }
  }, [])


  return (<Select
    key={`name-${props.deviceId}`}
    defaultValue={props?.value}
    onSearch={e => loadDeviceTelemetriesKeys()}
    onDropdownVisibleChange={e => {
      if (e) loadDeviceTelemetriesKeys()
    }}
    onChange={(e) => {
      if (props.NewData)
        props.NewData(e)
    }}
    style={{ width: 150 }}
  >
    {availableSensors?.map(tk => <Option value={tk}>{tk} </Option>)}
  </Select>)
}

const IZoneTemplate = ({ devices, tempalteUpdateHanlder }) => {

  const [showModal, setshowModal] = useState(false);

  const [selectedTemplate, setselectedTemplate] = useState({});

  const [availableTemplates, setavailableTemplates] = useState();

  const loadTemplates = () => {
    httpService.get("/v1/izone/templates").then(response => {
      const { data } = response;
      setavailableTemplates([...data]);
    })
  }

  const builTemplate = () => {

    var getSensors = availableTemplates?.find(findavail => findavail.name === selectedTemplate?.template);

    if (!getSensors)
      return;

    let sensors = getSensors?.data?.sensors?.map(mapsensor => {
      mapsensor["deviceId"] = selectedTemplate?.deviceId;
      mapsensor["value"] = "-";
      return mapsensor;
    })

    let alarms = getSensors?.data?.alarms?.map(mapalarms => {
      mapalarms["deviceId"] = selectedTemplate?.deviceId;
      mapalarms["date"] = null;
      mapalarms["status"] = "clear";

      return mapalarms
    })

    let templateInformation = { titles: getSensors?.data?.titles, sensors: sensors, alarms: alarms };
    tempalteUpdateHanlder(templateInformation);


    clearAllInput();
  }

  const clearAllInput = () => {
    setshowModal(false); setavailableTemplates(null);
    setselectedTemplate(null);
  }

  return (<>
    <Button style={{ margin: 10 }} onClick={e => { setshowModal(true); loadTemplates(); }} size="sm" variant="dark"><Bookmark /> Select Template</Button>
    <Modal
      centered
      forceRender={true}
      width={400}
      okType="success"
      title={`Template Selection`}
      visible={showModal}
      onOk={() => {
        builTemplate();
      }}
      onCancel={() => clearAllInput()}
    >

      {availableTemplates && <>
        <div style={{ margin: 5 }}>
          Device
          <Select style={{ width: "100%" }} onChange={e => setselectedTemplate(old => ({ ...old, "deviceId": e }))}>
            {devices?.map(mapoptions => (<Select.Option value={mapoptions?.value}>{mapoptions?.label}</Select.Option>))}
          </Select>
        </div>
        <div style={{ margin: 5 }}>
          Template
          <Select style={{ width: "100%" }} onChange={e => setselectedTemplate(old => ({ ...old, "template": e }))}>
            {availableTemplates?.map(mapoptions => (<Select.Option value={mapoptions?.name}>{mapoptions?.name}</Select.Option>))}
          </Select>
        </div>
      </>}


    </Modal>

  </>)
}

const MakeIZoneTemplate = ({ izone }) => {

  const [showModal, setshowModal] = useState(false);

  const [newTemplateInformation, setnewTemplateInformation] = useState();

  const closeClearInputs = () => {
    setnewTemplateInformation(null); setshowModal(false)
  }

  const createNewTempalte = () => {
    httpService.post("/v1/izone/templates", newTemplateInformation).then(response => {
      successMessage(`iZone template created successfully !`);
      closeClearInputs();
    }).catch(error => { errorMessage(error?.response?.data || error) });
  }

  return (<>
    <Button style={{ margin: 10 }} onClick={e => {
      setshowModal(true);
      let data = { sensors: izone.sensors, alarms: izone.alarms, titles: izone.titles };
      setnewTemplateInformation(old => ({ ...old, data }));

    }} size="sm" variant="warning"><Bookmark /> Create Template</Button>
    <Modal
      centered
      forceRender={true}
      width={400}
      okType="success"
      title={`Template Selection`}
      visible={showModal}
      onOk={() => {
        createNewTempalte();

      }}
      onCancel={() => { closeClearInputs() }}
    >

      <div>
        Template Name

      </div>
      <Input value={newTemplateInformation?.name} onChange={e => setnewTemplateInformation(old => ({ ...old, "name": e.target.value }))} />


    </Modal>

  </>)
}


export const IZoneAdvanceSettingsV2 = (props) => {
  const [notifications, setnotifications] = useState([
    "1W_TEMP_1 HIGH",
    "1W_TEMP_2 HIGH",
    "1W_TEMP_1 LOW",
    "1W_TEMP_2 LOW",
    "TEMPERATURE 1W HIGH",
    "PDU 1 OFF",
    "PDU 2 OFF",
    "TEMPERATURE 1W LOW",
    "TEMPERATURE 2W HIGH",
    "OFFLINE",
    "HUMIDITY 1 HIGH",
    "HUMIDITY 1 LOW",
    "TARGET OFFLINE",
    "ACTIVE POWER HIGH",
    "ACTIVE POWER LOW",
    "AC 1 OFF",
    "HUM_1 HIGH",
    "HUM 1 LOW",
    "DC VOLTAGE HIGH",
    "DC VOLTAGE LOW",
    "DC CURRENT HIGH",
    "DC CURRENT LOW",
    "DOOR 1 OPENED",
    "DOOR 2 OPENED",
    "WATER DETECTED",
    "IN1 REMIO PROBE 1 HIGH",
    "IN1 REMIO PROBE 2 HIGH",
    "IN2 REMIO PROBE 1 HIGH",
    "IN2 REMIO PROBE 2 HIGH",
    "IN3 REMIO PROBE 1 HIGH",
    "IN3 REMIO PROBE 2 HIGH",
    "IN4 REMIO PROBE 1 HIGH",
    "IN4 REMIO PROBE 2 HIGH",
    "IN1 REMIO PROBE 3 HIGH",
    "IN1 REMIO PROBE 4 HIGH",
    "IN2 REMIO PROBE 3 HIGH",
    "IN2 REMIO PROBE 4 HIGH",
    "IN3 REMIO PROBE 3 HIGH",
    "IN3 REMIO PROBE 4 HIGH",
    "IN4 REMIO PROBE 3 HIGH",
    "IN4 REMIO PROBE 4 HIGH",
    "IN5 REMIO PROBE 3 HIGH",
    "IN6 REMIO PROBE 3 HIGH",
    "IN7 REMIO PROBE 3 HIGH",
    "IN8 REMIO PROBE 3 HIGH",
    "AIRCON OFF", "WATER AIRCON", "AIRCON ERROR WATER PRESENT", "AIRCON NO POWER", "AIRCON COMMS ERROR"
  ]);

  const [sttingsToDisplay, setsttingsToDisplay] = useState([

  ]);

  const [sensorInfomation, setsensorInfomation] = useState([

  ]);

  const [commands, setcommands] = useState([

  ]);

  const [accessGroup, setaccessGroup] = useState([]);

  const [emails, setemails] = useState([]);

  const [availableDevices, setavailableDevices] = useState([]);

  const [
    commandsToExecuteOnNotifications,
    setcommandsToExecuteOnNotifications,
  ] = useState([]);

  const udpateIteminfo = (index, path, value) => {
    setsttingsToDisplay(prevData => {
      return prevData.map((item, i) => {
        if (i === index) {
          return { ...item, [path]: value };
        }
        return item;
      });
    });
  };
  

  const udpateIteminfov2 = (data, index, path, value, setdata) => {
    setdata(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [path]: value };
      return newData;
    });
  };
  
  const removeIteminfov2 = (data, index, setdata) => {
    setdata(prevData => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };
  

  const udpateIteminfov3 = (
    data,
    index,
    path,
    value,
    setdata,
    index2,
    subpath
  ) => {
    setdata(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [subpath]: [
          ...newData[index][subpath].slice(0, index2),
          {
            ...newData[index][subpath][index2],
            [path]: value
          },
          ...newData[index][subpath].slice(index2 + 1)
        ]
      };
      return newData;
    });
  };
  

  const removeIteminfov3 = (data, index, setdata, subpath, index2) => {
    setdata(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [subpath]: [...newData[index][subpath].slice(0, index2), ...newData[index][subpath].slice(index2 + 1)]
      };
      return newData;
    });
  };

  const updateNotificationInformation = () => {
    udpateSensorsCommands();

    httpService
      .put(`/v1/izone/${props.id}/alarms`, {
        alarms: sttingsToDisplay,
      })
      .then((res) => {
        successMessage(`Settings  udpated!`);
      })
      .catch((err) => {
        errorMessage(`Notification settings failed to udpated!`);
      });
  };

  const udpateSensorsCommands = () => {
    if (props?.izoneAll?.commands === commands) {
      successMessage(`Nothing has changed thank you !`);
      return;
    }
    httpService
      .put(`/v1/izone/${props.id}/sensors`, {
        sensors: sensorInfomation?.map(sensorinfomap => {
          let temp = sensorinfomap;
          temp.availableSensors = [];
          return temp;
        }),
        commands: commands,
        titles: addingModeElements
      })
      .then((res) => { })
      .catch((err) => {
        errorMessage(`advacnce settings failed to udpated!`);
      });
  };

  const loadEmails = async () => {
    httpService
      .get("/v1/accessgroup/lite")
      .then((res) => {
        const { data } = res;
        setaccessGroup([...data]);
      })
      .catch((eror) => {
        setaccessGroup([]);
      });

    httpService.get(`/v1/people?type=emails`).then((res) => {
      const { data } = res;
      setemails([...data]);
    });

    httpService
      .get(`/v1/building/availabledevices`)
      .then((res) => {
        const { data } = res;
        setavailableDevices([...data]);
      })
      .catch((error) => { });
  };

  const buildCommandsToExecute = () => {
    var mycomamnds = commands?.map((mycommandsmap) => {
      var tempmap = {
        label: mycommandsmap.name,
        value: mycommandsmap.name,
        children: mycommandsmap?.commands?.map(
          (commandsmap) =>
          ({
            label: commandsmap.label,
            value: `${commandsmap.label},${commandsmap.command}`,
          } || [])
        ),
      };
      return tempmap;
    });

    setcommandsToExecuteOnNotifications(mycomamnds);
  };

  const loadiZoneSettings = () => {
    setisLoading(true);
    httpService.get(`/v1/izone/${props.id}`).then(res => {
      const { data } = res;

      setrowiZoneData({ ...data });
      setsttingsToDisplay([...data.alarms]);

      setsensorInfomation([...data.sensors]);

      setcommands([...data.commands]);
      setaddingModeElements([...data.titles] || []);

      setisLoading(false);

    }).catch(error => {
      setisLoading(false);
    })
  }

  const [rowiZoneData, setrowiZoneData] = useState();

  const [isLoading, setisLoading] = useState(false);

  const [showModal, setshowModal] = useState(false);

  function loadDeviceTelemetriesKeys(deviceId, callbackValue) {
    httpService.get(`/v1/izone/device/${deviceId}/telemetrieskeys`).then(response => {
      const { data } = response;
      ///console.log(data);
      callbackValue(data);
    }).catch(error => { });
  }


  useEffect(() => {

    return () => { };
  },);

  const [addingModeElements, setaddingModeElements] = useState([]);
  const [newName, setnewName] = useState("");
  const [selectedSensor, setselectedSensor] = useState();

  const updateModelElemtvalue = (index, name, value) => {
    let currentValues = [...addingModeElements];
    currentValues[index][name] = value;
    setaddingModeElements(currentValues);
  }

  const AddNewSensor = (e) => {

    setsensorInfomation((old) => [
      ...old,
      {
        name: "",
        deviceId: "",
        label: "",
        type: "Number",
        symbole: "",
        value: "0",
      },
    ]);

  }
  const UpdateValueComponent = (index, item) => {


    Modal.info({
      title: 'Edit Title',
      centered: true,
      content: (
        <div>

          <Input defaultValue={item?.title} onChange={e => updateModelElemtvalue(index, "title", e.target.value)} />

        </div>
      ),
      onOk() { },
    });
  }



  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const updateSensorPostition = (sensorPosition) => {

    console.log("current information", sensorPosition);

    let newTitleValues = addingModeElements?.map(sensorMaped => {

      let findLocation = sensorPosition?.find(fsensor => fsensor.i === sensorMaped.name);
      if (findLocation) {
        sensorMaped["data"] = findLocation;
        return sensorMaped;
      }
      return sensorMaped;
    });

    let newSensorValues = sensorInfomation?.map(sensorMaped => {

      let findLocation = sensorPosition?.find(fsensor => fsensor.i === `Sensor_${sensorMaped.name}`);
      if (findLocation) {
        sensorMaped["data"] = findLocation;
        return sensorMaped;
      }
      return sensorMaped;
    });

    console.log("after updates of location", newTitleValues);

    setsensorInfomation(old => ([...newSensorValues]))
    setaddingModeElements(old => ([...newTitleValues]))
  }



  // Template 


  const [selectedTemplate, setselectedTemplate] = useState();




  return (
    <>
      <AntButton
        style={{ margin: 5 }}
        onClick={(e) => {
          loadEmails();
          buildCommandsToExecute();
          loadiZoneSettings();
          setshowModal(true);
        }}
      >
        <GearFill /> Settings
      </AntButton>
      <Modal
        forceRender={true}
        width={1200}
        okType="success"
        title={`Advance iZone Settings ${props.selectediZone?.name ?? ""}`}
        visible={showModal}
        onOk={() => {
          updateNotificationInformation();
        }}
        onCancel={() => setshowModal(false)}
      >
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <GridLoader
              margin={10}
              loading={isLoading}
              size={30}
              color="black"
            />
          </div>
        )}
        {!isLoading && (
          <>
            <IZoneTemplate devices={availableDevices} tempalteUpdateHanlder={e => {
              setsensorInfomation(e.sensors);
              setsttingsToDisplay(e.alarms);
              setaddingModeElements(e.titles);
              console.log("new template", e);
            }} />

            <MakeIZoneTemplate izone={{ sensors: sensorInfomation, alarms: sttingsToDisplay, titles: addingModeElements }} />
            <Collapse>
              <Panel header="Notification Settings" key="1">
                <Button
                  onClick={(e) => {
                    setsttingsToDisplay((old) => [
                      ...old,
                      {
                        name: "",
                        deviceId: "",
                        label: "",
                        status: "clear",
                        type: "red",
                      },
                    ]);
                  }}
                  size="sm"
                  style={{ margin: 10 }}
                  variant="dark"
                >
                  +
                </Button>{" "}
                Add Alarms
                <Table responsive>
                  <thead>
                    <th>iPoP</th>
                    <th>Notification</th>
                    <th>Alarm</th>
                    <th>Clear</th>
                    <th>Notificants</th>
                    <th>Severity</th>
                    <th>Extra Information</th>
                    <th></th>
                  </thead>
                  <tbody>
                    {sttingsToDisplay &&
                      sttingsToDisplay.map((mapalarmSettings, index) => (
                        <>
                          <tr style={{ margin: 5 }} key={`row-${index}`}>
                            <td>
                              <Select
                                defaultValue={mapalarmSettings["deviceId"]}
                                onChange={(e) => {
                                  udpateIteminfo(index, "deviceId", e);
                                }}
                                style={{ width: 200 }}
                              >
                                {availableDevices?.map((mapdevices) => (
                                  <Option value={mapdevices.value}>
                                    {mapdevices.label}{" "}
                                  </Option>
                                ))}
                              </Select>

                              {/* <Input
                            style={{ width: 150 }}
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "deviceId",
                                e.currentTarget.value
                              );
                            }}
                            defaultValue={mapalarmSettings.deviceId}
                            placeholder="Device Key"
                          /> */}
                            </td>
                            <td>
                              <Select
                                onSelect={(e) => {
                                  udpateIteminfo(index, "name", e);
                                }}
                                style={{ width: 200 }}
                                defaultValue={mapalarmSettings.name}
                              >
                                {notifications.map((mapno) => (
                                  <>
                                    <Option value={mapno}>{mapno}</Option>
                                  </>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <div style={{ fontWeight: "bold", width: 200 }}>Message</div>
                              <Input.TextArea
                                onChange={(e) => {
                                  udpateIteminfo(
                                    index,
                                    "label",
                                    e.currentTarget.value
                                  );
                                }}
                                defaultValue={mapalarmSettings.label}
                                placeholder="Alarm message"
                                rows={2}
                              />
                              <div style={{ fontWeight: "bold" }}>Actions</div>
                              <Cascader
                                style={{ width: "100%" }}
                                options={commandsToExecuteOnNotifications}
                                onChange={(e) => {
                                  //console.log(`Commands to execute ... `, e);
                                  udpateIteminfo(index, "commandToExecute", e);
                                }}
                                multiple
                                maxTagCount="responsive"
                                defaultValue={
                                  mapalarmSettings?.commandToExecute
                                }
                                showCheckedStrategy={SHOW_CHILD}
                              />
                            </td>
                            <td>
                              <div style={{ fontWeight: "bold", width: 200 }}>Message</div>
                              <Input.TextArea
                                rows={2}
                                onChange={(e) => {
                                  udpateIteminfo(
                                    index,
                                    "labelClear",
                                    e.currentTarget.value
                                  );
                                }}
                                defaultValue={mapalarmSettings?.labelClear}
                                placeholder="Clear Message"
                              />
                              <div style={{ fontWeight: "bold" }}>Actions</div>
                              <Cascader
                                style={{ width: "100%" }}
                                options={commandsToExecuteOnNotifications}
                                onChange={(e) => {
                                  //console.log(`Commands to execute ... `, e);
                                  udpateIteminfo(
                                    index,
                                    "commandToExecuteClear",
                                    e
                                  );
                                }}
                                multiple
                                maxTagCount="responsive"
                                defaultValue={
                                  mapalarmSettings?.commandToExecuteClear
                                }
                                showCheckedStrategy={SHOW_CHILD}
                              />
                            </td>
                            <td>
                              <Select
                                value={mapalarmSettings["emails"]}
                                onChange={(e) => {
                                  udpateIteminfo(index, "emails", e);
                                }}
                                style={{ width: 200 }}
                                mode="tags"
                              >
                                {emails?.map((mapemails) => (
                                  <Option value={mapemails.username}>
                                    {mapemails.username}{" "}
                                  </Option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Select
                                value={mapalarmSettings["type"]}
                                onChange={(e) => {
                                  udpateIteminfo(index, "type", e);
                                }}
                                style={{ width: 100 }}
                              >
                                <Option value="red">
                                  <Badge bg="danger">Critical</Badge>{" "}
                                </Option>
                                <Option value="orange">
                                  <Badge bg="warning">Warning</Badge>
                                </Option>
                                <Option value="yellow">
                                  <Badge bg="success">Info</Badge>{" "}
                                </Option>
                              </Select>
                            </td>
                            <td>
                              <Mentions
                                rows={3}
                                style={{ width: 200 }}
                                onChange={(e) => {
                                  udpateIteminfo(index, "extraInformation", e);
                                }}
                                defaultValue={
                                  mapalarmSettings?.extraInformation
                                }
                              >
                                {sensorInfomation?.map((mapsensor, index2) => (
                                  <Mentions.Option
                                    value={`{${mapsensor?.label
                                      .replace(/\s/g, "")
                                      .toLowerCase()}}`}
                                  >
                                    {mapsensor.label}
                                  </Mentions.Option>
                                ))}
                              </Mentions>
                              {/* <Input.TextArea
                            rows={2}
                            onChange={(e) => {
                              udpateIteminfo(
                                index,
                                "extraInformation",
                                e.currentTarget.value
                              );
                            }}
                            Value={mapalarmSettings?.extraInformation}
                            placeholder="Extra Information"
                          /> */}
                            </td>
                            <td>
                              <Button
                                onClick={(e) => {
                                  removeIteminfov2(
                                    sttingsToDisplay,
                                    index,
                                    setsttingsToDisplay
                                  );
                                }}
                                size="sm"
                                style={{ margin: 10, width: 20 }}
                                variant="danger"
                              >
                                {" "}
                                -
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </Panel>
              <Panel showArrow={true} header="Sensor Settings" key="2">
                <Button
                  onClick={AddNewSensor}
                  size="sm"
                  style={{ margin: 10 }}
                  variant="dark"
                >
                  +
                </Button>


                <Table responsive>
                  <thead>
                    <th>iPoP</th>
                    <th>Sensor</th>
                    <th>Label</th>
                    <th>Symbole</th>
                    <th>Type</th>
                    <th></th>
                  </thead>
                  <tbody>
                    {sensorInfomation?.map((mapsensor, index2) => (
                      <tr>
                        <td>
                          <Select
                            defaultValue={mapsensor["deviceId"]}
                            onChange={(e) => {
                              udpateIteminfov2(
                                sensorInfomation,
                                index2,
                                "deviceId",
                                e,
                                setsensorInfomation
                              );
                            }}
                            style={{ width: 200 }}
                          >
                            {availableDevices?.map((mapdevices) => (
                              <Option value={mapdevices.value}>
                                {mapdevices.label}{" "}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Select
                            key={`name-${index2}`}
                            style={{ width: 150 }}
                            defaultValue={mapsensor.name}
                            onChange={(e) => {
                              udpateIteminfov2(
                                sensorInfomation,
                                index2,
                                "name",
                                e,
                                setsensorInfomation
                              );
                            }}
                            onDropdownVisibleChange={(e) => {
                              if (e)
                                loadDeviceTelemetriesKeys(
                                  mapsensor.deviceId,
                                  (callbackValue) => {
                                    udpateIteminfov2(
                                      sensorInfomation,
                                      index2,
                                      "availableSensors",
                                      callbackValue,
                                      setsensorInfomation
                                    );
                                  }
                                );
                            }}
                          >
                            {mapsensor?.availableSensors?.map(
                              (sensormapAvailable) => (
                                <Option value={sensormapAvailable}>
                                  {sensormapAvailable}
                                </Option>
                              )
                            )}
                          </Select>
                          {/*                     
                      <Input
                      
                        key={`name-${index2}`}
                        style={{ width: 150 }}
                        onChange={(e) => {
                          udpateIteminfov2(
                            sensorInfomation,
                            index2,
                            "name",
                            e.currentTarget.value,
                            setsensorInfomation
                          );
                        }}
                        value={mapsensor.name}
                        placeholder="Sensor Name"
                      /> */}
                        </td>
                        <td>
                          <Input
                            key={`label-${index2}`}
                            style={{ width: 250 }}
                            onChange={(e) => {
                              udpateIteminfov2(
                                sensorInfomation,
                                index2,
                                "label",
                                e.currentTarget.value,
                                setsensorInfomation
                              );
                            }}
                            value={mapsensor?.label}
                            placeholder="label"
                          />
                        </td>
                        <td>
                          <Input
                            style={{ width: 50 }}
                            onChange={(e) => {
                              udpateIteminfov2(
                                sensorInfomation,
                                index2,
                                "symbole",
                                e.currentTarget.value,
                                setsensorInfomation
                              );
                            }}
                            value={mapsensor.symbole}
                            placeholder="symbole"
                          />
                        </td>
                        <td>
                          <Select
                            value={mapsensor["type"]}
                            onChange={(e) => {
                              udpateIteminfov2(
                                sensorInfomation,
                                index2,
                                "type",
                                e,
                                setsensorInfomation
                              );
                            }}
                            style={{ width: 100 }}
                          >
                            <Option value="Number">Number </Option>
                            <Option value="Status">Status</Option>
                            <Option value="Range">Range</Option>
                          </Select>

                          {mapsensor["type"] === "Status" && (
                            <>
                              <Button
                                onClick={(e) => {
                                  let temp = sensorInfomation;
                                  //console.log(temp[index2]);
                                  if (!temp[index2]["valueLables"]) {
                                    temp[index2]["valueLables"] = [
                                      {
                                        value: "0",
                                        label: "",
                                        color: "success",
                                      },
                                    ];
                                  } else {
                                    temp[index2]["valueLables"] = [
                                      ...temp[index2]["valueLables"],
                                      {
                                        value: "0",
                                        label: "",
                                        color: "success",
                                      },
                                    ];
                                  }

                                  setsensorInfomation(temp);
                                }}
                                size="sm"
                                style={{ margin: 10 }}
                                variant="dark"
                              >
                                +
                              </Button>
                              <Table responsive>
                                <thead>
                                  <th>Value</th>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Commands</th>
                                  <th></th>
                                </thead>
                                <tbody>
                                  {mapsensor?.valueLables?.map(
                                    (mapsensorlabels, mapsensorlabelsindex) => (
                                      <>
                                        <tr>
                                          <td>
                                            {" "}
                                            <Input
                                              style={{ width: 70, margin: 5 }}
                                              onChange={(e) => {
                                                udpateIteminfov3(
                                                  sensorInfomation,
                                                  index2,
                                                  "value",
                                                  e.currentTarget.value,
                                                  setsensorInfomation,
                                                  mapsensorlabelsindex,
                                                  "valueLables"
                                                );
                                              }}
                                              defaultValue={
                                                mapsensorlabels.value
                                              }
                                              placeholder="value"
                                            />
                                          </td>
                                          <td>
                                            {" "}
                                            <Input
                                              style={{ width: 100, margin: 5 }}
                                              onChange={(e) => {
                                                udpateIteminfov3(
                                                  sensorInfomation,
                                                  index2,
                                                  "label",
                                                  e.currentTarget.value,
                                                  setsensorInfomation,
                                                  mapsensorlabelsindex,
                                                  "valueLables"
                                                );
                                              }}
                                              defaultValue={
                                                mapsensorlabels.label
                                              }
                                              placeholder="label"
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              value={mapsensorlabels["color"]}
                                              onChange={(e) => {
                                                udpateIteminfov3(
                                                  sensorInfomation,
                                                  index2,
                                                  "color",
                                                  e,
                                                  setsensorInfomation,
                                                  mapsensorlabelsindex,
                                                  "valueLables"
                                                );
                                              }}
                                              style={{ width: 100, margin: 5 }}
                                            >
                                              <Option value="danger">
                                                <Badge bg="danger">
                                                  danger
                                                </Badge>{" "}
                                              </Option>
                                              <Option value="warning">
                                                <Badge bg="warning">
                                                  warning
                                                </Badge>
                                              </Option>
                                              <Option value="succ1W_TEMP_1 HIGHess">
                                                <Badge bg="success">
                                                  success
                                                </Badge>{" "}
                                              </Option>
                                            </Select>
                                          </td>

                                          <td>
                                            <Button
                                              onClick={(e) => {
                                                removeIteminfov3(
                                                  sensorInfomation,
                                                  index2,
                                                  setsensorInfomation,
                                                  "valueLables",
                                                  mapsensorlabelsindex
                                                );
                                              }}
                                              size="sm"
                                              style={{ margin: 5 }}
                                              variant="danger"
                                            >
                                              -
                                            </Button>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </>
                          )}
                          {mapsensor["type"] === "Number" && (
                            <>
                              <Table responsive>
                                <thead>
                                  <th>Max</th>
                                  <th>Min</th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Input
                                        style={{ width: 50 }}
                                        onChange={(e) => {
                                          udpateIteminfov2(
                                            sensorInfomation,
                                            index2,
                                            "max",
                                            e.currentTarget.value,
                                            setsensorInfomation
                                          );
                                        }}
                                        defaultValue={mapsensor.max}
                                        placeholder="max"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        style={{ width: 50 }}
                                        onChange={(e) => {
                                          udpateIteminfov2(
                                            sensorInfomation,
                                            index2,
                                            "min",
                                            e.currentTarget.value,
                                            setsensorInfomation
                                          );
                                        }}
                                        defaultValue={mapsensor.min}
                                        placeholder="min"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </>
                          )}
                          {mapsensor["type"] === "Range" && (
                            <>
                              <Input
                                style={{ width: 200, marginLeft: 10 }}
                                onChange={(e) => {
                                  udpateIteminfov2(
                                    sensorInfomation,
                                    index2,
                                    "updateCommand",
                                    e.currentTarget.value,
                                    setsensorInfomation
                                  );
                                }}
                                defaultValue={mapsensor.updateCommand}
                                placeholder="update Command"
                              />
                            </>
                          )}
                        </td>

                        <td>
                          {index2}
                          <Button
                            size="sm"
                            style={{ margin: 10, width: 20 }}
                            variant="warning"
                            onClick={(e) => {
                              var currentArray = [...sensorInfomation];
                              currentArray.splice(index2, 0, {
                                name: "",
                                deviceId: mapsensor.deviceId,
                                label: "",
                                type: "Number",
                                symbole: "",
                                value: "0",
                              });
                              setsensorInfomation(currentArray);
                            }}
                          >
                            +
                          </Button>

                          <Button
                            onClick={(e) => {
                              removeIteminfov2(
                                sensorInfomation,
                                index2,
                                setsensorInfomation
                              );
                            }}
                            size="sm"
                            style={{ margin: 10 }}
                            variant="danger"
                          >
                            -
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Panel>
              <Panel showArrow={true} header="iZone Commands" key="3">
                <Button
                  onClick={(e) => {
                    setcommands((old) => [
                      ...old,
                      {
                        name: "",
                        deviceId: "",
                        commands: [],
                      },
                    ]);
                  }}
                  size="sm"
                  style={{ margin: 10 }}
                  variant="dark"
                >
                  +
                </Button>
                <Table responsive>
                  <thead>
                    <th>Device Key</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Commands</th>
                    <th>Access Group</th>
                    <th></th>
                  </thead>
                  <tbody>
                    {commands?.map((mapcommand, index2) => (
                      <tr>
                        <td>
                          <Select
                            defaultValue={mapcommand["deviceId"]}
                            onChange={(e) => {
                              udpateIteminfov2(
                                commands,
                                index2,
                                "deviceId",
                                e,
                                setcommands
                              );
                            }}
                            style={{ width: 200 }}
                          >
                            {availableDevices?.map((mapdevices) => (
                              <Option value={mapdevices.value}>
                                {mapdevices.label}{" "}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Input
                            style={{ width: 200 }}
                            onChange={(e) => {
                              udpateIteminfov2(
                                commands,
                                index2,
                                "name",
                                e.currentTarget.value,
                                setcommands
                              );
                            }}
                            defaultValue={mapcommand?.name}
                            placeholder="Name"
                          />
                        </td>
                        <td>
                          <Select
                            defaultValue={mapcommand?.type}
                            onChange={(e) => {
                              udpateIteminfov2(
                                commands,
                                index2,
                                "type",
                                e,
                                setcommands
                              );
                            }}
                            style={{ width: 100, margin: 5 }}
                          >
                            <Option value="Standard">
                              <Badge bg="success">Standard</Badge>{" "}
                            </Option>
                            <Option value="Legacy">
                              <Badge bg="warning">Legacy</Badge>
                            </Option>
                          </Select>
                        </td>
                        <td>
                          <Button
                            onClick={(e) => {
                              let temp = [...commands];
                              temp[index2]["commands"] = [
                                ...temp[index2]["commands"],
                                { label: "", command: "", value: 0 },
                              ];
                              setcommands(temp);
                            }}
                            size="sm"
                            style={{ margin: 10 }}
                            variant="dark"
                          >
                            +
                          </Button>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>MODE</th>
                                <th>Name</th>
                                <th>Command</th>
                                <th>Value</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {mapcommand?.commands?.map(
                                (
                                  mapcomamndoprions,
                                  indexmoreoptionscommand
                                ) => (
                                  <>
                                    <tr>
                                        <td>
                                        {" "}
                                        <Input
                                          style={{ width: 70, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              commands,
                                              index2,
                                              "mode",
                                              e.currentTarget.value,
                                              setcommands,
                                              indexmoreoptionscommand,
                                              "commands"
                                            );
                                          }}
                                          defaultValue={mapcomamndoprions?.mode}
                                          placeholder="Mode"
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <Input
                                          style={{ width: 70, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              commands,
                                              index2,
                                              "label",
                                              e.currentTarget.value,
                                              setcommands,
                                              indexmoreoptionscommand,
                                              "commands"
                                            );
                                          }}
                                          defaultValue={mapcomamndoprions.label}
                                          placeholder="label"
                                        />
                                      </td>

                                      <td>
                                        {" "}
                                        <Input
                                          style={{ width: 130, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              commands,
                                              index2,
                                              "command",
                                              e.currentTarget.value,
                                              setcommands,
                                              indexmoreoptionscommand,
                                              "commands"
                                            );
                                          }}
                                          defaultValue={
                                            mapcomamndoprions.command
                                          }
                                          placeholder="command"
                                        />
                                      </td>
                                      <td>
                                        {" "}
                                        <Input
                                          style={{ width: 50, margin: 5 }}
                                          onChange={(e) => {
                                            udpateIteminfov3(
                                              commands,
                                              index2,
                                              "value",
                                              e.currentTarget.value.toString(),
                                              setcommands,
                                              indexmoreoptionscommand,
                                              "commands"
                                            );
                                          }}
                                          defaultValue={
                                            mapcomamndoprions?.value
                                          }
                                          placeholder="value"
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          onClick={(e) => {
                                            removeIteminfov3(
                                              commands,
                                              index2,
                                              setcommands,
                                              "commands",
                                              indexmoreoptionscommand
                                            );
                                          }}
                                          size="sm"
                                          style={{ margin: 5 }}
                                          variant="danger"
                                        >
                                          -
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}
                            </tbody>
                          </Table>
                        </td>
                        <td>
                          <Select
                            mode="tags"
                            value={mapcommand["accessGroup"]}
                            onChange={(e) => {
                              udpateIteminfov2(
                                commands,
                                index2,
                                "accessGroup",
                                e,
                                setcommands
                              );
                            }}
                            style={{ width: 200 }}
                          >
                            {accessGroup?.map((mapdevices) => (
                              <Option value={mapdevices._id}>
                                {mapdevices.name}{" "}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Button
                            onClick={(e) => {
                              removeIteminfov2(commands, index2, setcommands);
                            }}
                            size="sm"
                            style={{ margin: 10 }}
                            variant="danger"
                          >
                            -
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Panel>
              <Panel header="Dashboard" key="4">

                <div style={{ border: "1px black", margin: 10, background: "black", color: "white", textAlign: "center" }}>
                  Components
                  <Row>
                    <Col>
                      <div
                        style={{
                          background: "white",
                          width: 50,
                          height: 50,
                          margin: 5,
                          textAlign: "center",
                          color: "black"
                        }}
                        draggable={true}
                        unselectable="on"
                        onDragStart={(e) =>
                          e.dataTransfer.setData("text/plain", "sensor")
                        }
                      >
                        Sensor
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          background: "White",
                          width: 200,
                          height: 20,
                          margin: 5,
                          textAlign: "center",
                          color: "black"
                        }}
                        draggable={true}
                        unselectable="on"
                        onDragStart={(e) =>
                          e.dataTransfer.setData("text/plain", "text")
                        }
                      >
                        Text
                      </div>
                    </Col>
                    {/* <Col>
                      <div

                      >
                        <Button style={{

                          margin: 5,
                        }}
                          draggable={true}
                          unselectable="on"
                          onDragStart={(e) =>
                            e.dataTransfer.setData("text/plain", "button")
                          } variant="warning" size="sm">Button</Button>
                      </div>
                    </Col> */}


                  </Row>
                </div>

                <div style={{ width: 800 }}>
                  <div style={{ textAlign: "center" }}>
                    <GridLayout
                      ref={componentRef}
                      className="layout"
                      layout={[]}
                      isDroppable={true}
                      rowHeight={20}
                      width={1200}
                      onDragStop={(e) => {
                        //console.log("Draggin:", e);
                        updateSensorPostition(e);
                      }}

                      onResizeStop={e => {
                        updateSensorPostition(e);
                      }}

                      onDrop={(e, litem, _event) => {
                        const data = _event.dataTransfer.getData("text");

                        if (data === "sensor")
                          AddNewSensor(null);
                        else if (data === "button")
                          setaddingModeElements((old) => [...old, { data: { ...litem } }]);
                        else
                          setaddingModeElements((old) => [...old, { data: { ...litem }, name: `title_${old.length}` }]);
                      }}

                    >
                      {sensorInfomation?.map((mapsensor, index2) => (
                        <div
                          data-grid={mapsensor?.data}
                          style={{ height: "100%" }}
                          key={`Sensor_${mapsensor?.name}`}
                          onDoubleClick={e => setselectedSensor([sensorInfomation[index2]])}
                        >
                          {" "}
                          <SensorInformationDisplay onDouble={e => { }}  {...mapsensor}>
                            <h6>Value</h6>
                          </SensorInformationDisplay>
                        </div>
                      ))}

                      {addingModeElements?.map((add, index) => {
                        if (add.type !== "button")
                          return (<div
                            onDoubleClick={e => UpdateValueComponent(index)}
                            data-grid={{ ...add.data }}
                            style={{ color: "black", margin: 0, textAlign: "center", height: 200, width: 200, border: '0.5px solid black' }}
                            key={`title_${index}`}
                          >
                            <h6 cli style={{ margin: 0 }}>  {add?.title || "Title"} <CloseCircleFilled onClick={e => {
                              let temp = [...addingModeElements];
                              temp.splice(index, 1);
                              setaddingModeElements([...temp]);
                            }} style={{ color: "red" }} /></h6>


                          </div>)

                        if (add.type === "button")
                          return (<Button
                            variant="warning"
                            onDoubleClick={e => UpdateValueComponent(index)}
                            data-grid={{ ...add.data }}
                            size="sm"
                            key={`add_${index}`}
                          >
                            {add?.name}

                          </Button>)
                      }
                      )}
                    </GridLayout>
                  </div>
                </div>


              </Panel>
            </Collapse>
          </>
        )}
      </Modal>
    </>
  );
};

export const SensorInformationMenu = (props) => {


}

export const IZoneTresholds = (props) => {
  const loadTresholds = () => {
    httpService
      .post(`/v1/izone/devices/attributes`, props?.query?.query)
      .then((res) => {
        const { data } = res;
        settresholdsSettings(data);
      })
      .catch((err) => { });
  };

  const getTelemetries = () => {
    setloading(true);
    httpService
      .get(
        `/v1/telemetries/devices/${props?.query?.deviceId}/avg?aggregate=${searchSettings.aggragation}&value=${searchSettings.time}&from=${dateRanger[0]}&to=${dateRanger[1]}&sensors=${props?.query?.query?.keys}`
      )
      .then((res) => {
        const { data } = res;
        settelemetriesData([...data]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const getRawTelemetries = ()=>{
    setloading(true);
    httpService
      .get(
        `/v1/telemetries/devices/${props?.query?.deviceId}?from=${dateRanger[0]}&to=${dateRanger[1]}&sensors=${props?.query?.query?.keys}`
      )
      .then((res) => {
        const { data } = res;
        setrawTelemetriesData([...data]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  useEffect(() => {
    loadTresholds();

    return () => { };
  }, []);

  const [tresholdsSettings, settresholdsSettings] = useState();

  const [dateRanger, setdateRanger] = useState([
    moment().subtract("minutes", 30).toISOString(),
    moment().toISOString(),
  ]);

  const data = [
    { name: "Page A", data: { temp: 30 }, uv: 400, pv: 2400, amt: 2400 },
    { name: "Page A", data: { temp: 70 }, uv: 200, pv: 600, amt: 800 },
  ];

  const [telemetriesData, settelemetriesData] = useState();

  const [rawTelemetriesData, setrawTelemetriesData] = useState()

  const [loading, setloading] = useState(false);

  const [searchSettings, setsearchSettings] = useState({
    aggragation: "minute",
    time: 1,
  });

  return (
    <>
      <Modal
        forceRender={true}
        okType="success"
        width={700}
        title={`iZone - ${props?.query?.label || ""} - Sensor Tresholds `}
        visible={props?.query}
        onOk={() => { }}
        onCancel={() => {
          if (props?.close) props?.close();
        }}
      >
        <Collapse bordered={true}>
          <Panel header="Tresholds" key="1">
            <div style={{ textAlign: "center", margin: 5 }}>
              <h6></h6>

              {tresholdsSettings && (
                <>
                  <div style={{ textAlign: "left" }}>Limits</div>
                  <Slider
                    marks={{
                      [props?.query
                        ?.max]: `${props?.query?.max}${props?.query?.symbole}`,
                      [props?.query
                        ?.min]: `${props?.query?.min}${props?.query?.symbole}`,
                    }}
                    max={Number(props?.query?.max) || 100}
                    min={Number(props?.query?.min) || 0}
                    onAfterChange={(e) => {
                      settresholdsSettings(e);
                      sendupdateTresholdCommand(
                        props?.query?.deviceId,
                        props?.query?.name,
                        e
                      );
                    }}
                    range={{ draggableTrack: true }}
                    defaultValue={[tresholdsSettings[0], tresholdsSettings[1]]}
                  />
                  <div style={{ textAlign: "left" }}>
                    Waiting time before clearing the alarm when the value
                    returns to the range :{" "}
                    <InputNumber
                      formatter={(value) => `${value} Minutes`}
                      min={0}
                      max={10}
                      defaultValue={2}
                      onChange={(e) => {
                        sendupdateTresholdCommandGeneric(
                          props?.query?.deviceId,
                          props?.query?.name,
                          e
                        );
                      }}
                      style={{ width: 100 }}
                    />
                  </div>
                </>
              )}
            </div>
          </Panel>
          <Panel header="History" key="2">
            <RangePicker
              defaultValue={[moment().subtract("minutes", 30), moment()]}
              onChange={(e) => {
                //console.log(`e`, e);
                setdateRanger([e[0].toISOString(), e[1].toISOString()]);
              }}
              showTime
            />
            <Select
              defaultValue={searchSettings.aggragation}
              onChange={(e) => {
                setsearchSettings((old) => ({ ...old, aggragation: e }));
              }}
              style={{ width: 100, marginLeft: 5 }}
            >
              <Option value="minute">Minutes</Option>
              <Option value="hour">Hours</Option>
              <Option value="day">Days</Option>
              <Option value="month">Month</Option>
              <Option value="year">Years</Option>
            </Select>
            <Select
              defaultValue={searchSettings.time}
              onChange={(e) => {
                setsearchSettings((old) => ({ ...old, time: Number(e) }));
              }}
              style={{ width: 60, marginLeft: 5 }}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="5">5</Option>
              <Option value="10">10</Option>
              <Option value="15">15</Option>
              <Option value="30">30</Option>
              <Option value="60">60</Option>
            </Select>
            <Button
              disabled={loading}
              size="sm"
              style={{ margin: 5 }}
              onClick={(e) => getTelemetries()}
              variant="dark"
            >
              Search
            </Button>
            <div>

              {telemetriesData && (
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    data={telemetriesData}
                    width={600}
                    height={300}
                    margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                  >
                    <Area
                      type="monotone"
                      dataKey={`_avg_${props?.query?.query?.keys}`}
                      stroke="black"
                      fill="black"
                    />

                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="ts" />
                    <YAxis />
                    <TooltipRehart content={CustomTooltip} />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div>
          </Panel>
          <Panel header="Telemetries" key="3">
          <RangePicker
              defaultValue={[moment().subtract("minutes", 30), moment()]}
              onChange={(e) => {
                //console.log(`e`, e);
                setdateRanger([e[0].toISOString(), e[1].toISOString()]);
              }}
              showTime
            />
            <Button
              disabled={loading}
              size="sm"
              style={{ margin: 5 }}
              onClick={(e) => getRawTelemetries()}
              variant="dark"
            >
              Search
            </Button>
            
            <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={[
            {
              name:"Date",
              cell:row=> <p>{moment(row?.ts).format("YYYY-MM-DD HH:mm:ss")}</p>
            },
            {
              name:"Value",
              cell:row=> <p>

                {/* {props?.query?.query?.keys?.split(',')?.map(keysplit=> <>{row?.data[keysplit]}</>)} */}
                {props?.query?.query?.keys?.split(',')?.map(keysplit=> <>
                  {row?.data[keysplit]}
                 </>)}
               
                
                </p>
            }
          ]}
          data={rawTelemetriesData}
          />
          
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
};

export const IZoneTresholdsAdvance = (props) => {
  const loadTresholds = () => {
    httpService
      .post(`/v1/izone/devices/attributes`, props?.query?.query)
      .then((res) => {
        const { data } = res;
        settresholdsSettings(data);
      })
      .catch((err) => { });
  };

  const getTelemetries = () => {


    setloading(true);
    httpService
      .post(
        `/v1/telemetries/devices/group/basic?aggregate=${searchSettings.aggragation}&value=${searchSettings.time}&from=${dateRanger[0]}&to=${dateRanger[1]}`
        , telemetriesSelected)
      .then((res) => {
        const { data } = res;
        settelemetriesData([...data]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    loadTresholds();

    return () => { };
  }, []);

  const [tresholdsSettings, settresholdsSettings] = useState();

  const [telemetriesSelected, settelemetriesSelected] = useState([]);

  const [dateRanger, setdateRanger] = useState([
    moment().subtract("minutes", 30).toISOString(),
    moment().toISOString(),
  ]);



  const [telemetriesData, settelemetriesData] = useState();

  const [loading, setloading] = useState(false);

  const [searchSettings, setsearchSettings] = useState({
    aggragation: "minute",
    time: 1,
  });

  const [showModal, setshowModal] = useState(false);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <AntButton style={{ margin: 2 }} onClick={e => setshowModal(true)}> <InfoCircle />  Telemetries Repport</AntButton>
      <Modal
        forceRender={true}
        okType="success"
        width={1300}
        title={`Telemetries`}
        visible={showModal}
        onOk={() => { }}
        onCancel={() => {
          setshowModal(false);
          settelemetriesData([]);
          settelemetriesSelected(NutFill)
        }}
      >
        <Button size="sm" style={{ margin: 10 }} variant="dark" onClick={handlePrint}><PrinterFill /> Print</Button>
        <Collapse bordered={true}>

          <Panel header="History" key="2">
            <div ref={componentRef} style={{ margin: 5 }}>
              <div style={{ marginBottom: 5 }} >

                <div>Sensors</div>

                <Select onChange={(e) => {
                  var reallPayload = e?.map(selectedp => {
                    var jsonvalue = JSON.parse(selectedp);
                    return jsonvalue;
                  })
                  settelemetriesSelected(reallPayload);
                }} style={{ width: "100%" }} mode="tags">
                  {props?.sensors?.filter(sfilter => sfilter.type === "Number")?.map(mapsensorstags => <Option value={JSON.stringify({ id: mapsensorstags.deviceId, sensor: mapsensorstags.name, label: mapsensorstags.label })}>{mapsensorstags.label}</Option>)}
                </Select>
              </div>

              <RangePicker
                defaultValue={[moment().subtract("minutes", 30), moment()]}
                onChange={(e) => {
                  // console.log(`e`, e);
                  setdateRanger([e[0].toISOString(), e[1].toISOString()]);
                }}
                showTime
              />
              <Select
                defaultValue={searchSettings.aggragation}
                onChange={(e) => {
                  setsearchSettings((old) => ({ ...old, aggragation: e }));
                }}
                style={{ width: 100, marginLeft: 5 }}
              >
                <Option value="minute">Minutes</Option>
                <Option value="hour">Hours</Option>
                <Option value="day">Days</Option>
                <Option value="month">Month</Option>
                <Option value="year">Years</Option>
              </Select>
              <Select
                defaultValue={searchSettings.time}
                onChange={(e) => {
                  setsearchSettings((old) => ({ ...old, time: Number(e) }));
                }}
                style={{ width: 60, marginLeft: 5 }}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="15">15</Option>
                <Option value="30">30</Option>
                <Option value="60">60</Option>
              </Select>
              <Button
                disabled={loading}
                size="sm"
                style={{ margin: 5 }}
                onClick={(e) => getTelemetries()}
                variant="dark"
              >
                Search
              </Button>
              <div>
                <Row>
                  {telemetriesData?.map(telemetriesMap =>

                    <Col lg={4}>
                      <>
                        <h6>{telemetriesMap?.label}</h6>
                        <ResponsiveContainer width="100%" height={300}>

                          <AreaChart

                            data={telemetriesMap.data}
                            width={600}
                            height={300}
                            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                            syncId="anyId"
                          >
                            <Area
                              type="monotone"
                              dataKey={telemetriesMap.selector}
                              stroke="black"
                              fill="black"
                            />

                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <XAxis dataKey="ts" />
                            <YAxis />
                            <TooltipRehart content={CustomTooltip} />
                          </AreaChart>
                        </ResponsiveContainer>
                      </>
                    </Col>
                  )}
                </Row>

                {/* {telemetriesData && (
               
              )} */}
              </div>
            </div>

          </Panel>
        </Collapse>
      </Modal>
    </>
  );
};

export const AlarmSearch = (props) => {
  const [dateRanger, setdateRanger] = useState([
    moment().subtract("minutes", 30).toISOString(),
    moment().toISOString(),
  ]);
  const [eventsLogs, seteventsLogs] = useState();

  const [isBusy, setisBusy] = useState(false);

  useEffect(() => {
    return () => { };
  }, []);

  const findevents = () => {
    setisBusy(true);
    httpService
      .post("/v1/activitieslogs/search/daterange", {
        from: dateRanger[0],
        to: dateRanger[1],
      })
      .then((response) => {
        const { data } = response;
        seteventsLogs([...data]);
        setisBusy(false);
      })
      .catch((error) => {
        seteventsLogs([]);
        setisBusy(false);
      });
  };

  const generateColor = (messageType) => {
    if (messageType === "warning") return "red";
    else return "black";
  };

  const [csvEvents, setcsvEvents] = useState([]);

  return (
    <>
      <Modal
        footer={[]}
        forceRender={true}
        okType="success"
        width={900}
        title={`Events Search`}
        visible={props?.query}
        onOk={() => { }}
        onCancel={() => {
          if (props?.close) {
            props?.close();
            seteventsLogs([]);
          }
        }}
      >
        <p>
          <RangePicker
            defaultValue={[moment().subtract("minutes", 30), moment()]}
            onChange={(e) => {
              setdateRanger([e[0].toISOString(), e[1].toISOString()]);
            }}
            showTime
          />
          <Button
            disabled={isBusy}
            onClick={() => findevents()}
            size="sm"
            style={{ marginLeft: 5 }}
            variant="dark"
          >
            Search
          </Button>
          {!isBusy && <CSVLink filename={`Event Repport_${moment().format("DD_MM_YYYY_hh_mm_ss")}`} onClick={(e, done) => {
            let csvToDownload = eventsLogs?.map(eventValue => ({ date: eventValue.ts, origin: eventValue.metadata?.originatorName, event: eventValue.metadata?.originatorName }))
            setcsvEvents([...csvToDownload]);
          }} data={csvEvents}><Button style={{ marginLeft: 5 }} variant="dark" size="sm">Download</Button></CSVLink>
          }

        </p>

        <p>
          <DataTable
            conditionalRowStyles={[
              {
                when: (row) => row?.metadata?.messageType === "warning",
                style: { background: "white", color: "red" },
              },
              {
                when: (row) => row?.metadata?.messageType != "warning",
                style: { background: "white", color: "green" },
              },
              {
                when: (row) => row?.metadata?.messageType === "alarm",
                style: { background: "white", color: "red" },
              },
            ]}
            pagination={true}
            paginationPerPage={10}
            columns={[
              {
                name: "Date",
                sortable: true,
                selector: "ts",
                cell: (row) =>
                  `${moment(row.ts).format("YYYY-MM-DD HH:mm:ss")}`,
              },
              {
                name: "Origin",
                sortable: true,
                selector: (row) => row?.metadata?.originatorName,
                cell: (row) => <p>{row?.metadata?.originatorName}</p>,
              },
              {
                name: "Event",

                sortable: true,
                selector: (row) => row.data.message,
                cell: (row) => <p>{row.data.message}</p>,
              },
            ]}
            data={eventsLogs}
          />
        </p>
      </Modal>
    </>
  );
};

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div style={{ background: "white", opacity: 0.6 }}>
        <p className="label">
          {" "}
          {payload && `${moment(label).format("YYYY-MM-DD HH:mm:ss")} : ${payload[0]?.value
            }`}
        </p>
      </div>
    );
  }

  return null;
}

export const IZoneCU = (props) => {
  return (
    <>
      <CustomModalV2
        close={props.close}
        forceShow={props.forceShow}
        load={props.load}
        centered={false}
        size="md"
        proccess={props.proccess}
        canProccess={true}
        proccessTitle={props.type}
        title={props.title}
        mainComponents={props.titleComponent}
      >
        {props?.fields?.map((a) => (
          <GenericFormElement
            {...a}
            value={props.data && props.data[a.name]}
            handleChange={(e) => props.handleChange(a.name, e.target.value)}
          />
        ))}
        {props?.multipleSelect}
        {props?.moreComponents}
      </CustomModalV2>
    </>
  );
};

const ListOfPeople = (props) => {
  return (
    <IZoneCU
      data={props.izoneInfo}
      proccess={() => props.updateZone(props.row._id)}
      handleChange={props.updateInfo}
      load={() => {
        props.loadPeopleiZones(props.row._id);
      }}
      fields={[]}
      type="OK"
      title={`People in ${props.row.name}`}
      titleComponent={
        <Button
          title={`People in ${props.row.name}.`}
          style={{ margin: 2 }}
          variant="warning"
          size="sm"
        >
          <People />
        </Button>
      }
      multipleSelect={
        <>
          <DataTable
            pagination={true}
            paginationPerPage={10}
            columns={props.izonePeopleColums}
            data={props.peopleInSelectedZone}
          />
        </>
      }
    />
  );
};

export const ListOfPeopleV2 = (props) => {
  const izonePeopleColums = [
    {
      sortable: true,
      name: "Person",
      selector: (row) => row?.person?._id,
      grow: 2,
      cell: (row) => (
        <>
          {!row?.picture && (
            <>
              {row?.name} {row?.surname}
            </>
          )}
          {row?.picture && (
            <>

              <UpdatePersonV3
                id={row?.person?._id}
                label={`${row?.name} ${row?.surname}`}
              />{" "}
            </>
          )}
        </>
      ),
    },
    {
      sortable: true,
      name: "Last activy",
      grow: 2,
      selector: (row) => row.lastActivityTime,
      cell: (row) => (
        <>{moment(row.lastActivityTime).format("MMMM Do YYYY, h:mm:ss a")}</>
      ),
    },
  ];

  return (
    <IZoneCU
      data={props.izoneInfo}
      proccess={() => { }}
      fields={[]}
      type="OK"
      title={`People in ${props.row.name}`}
      titleComponent={props.titleComponent}
      multipleSelect={
        <>
          <DataTable
            pagination={true}
            paginationPerPage={10}
            columns={izonePeopleColums}
            data={props.peopleInSelectedZone}
          />
        </>
      }
    />
  );
};

export const UpdaZoneLocations = (props) => {
  const [selctediZone, setselctediZone] = useState({});
  const [izonesAvailables, setizonesAvailables] = useState();

  const updateZone = () => {
    httpService
      .put(`/v1/izone/${selctediZone.value}/cordinates`, {
        floor: props.currentSelectedBuilding._id,
        cordinates: props.forceShow,
      })
      .then((r) => {
        successMessage(
          `${selctediZone.label} cordinates have been successfully updated`
        );
      })
      .catch((err) => {
        errorMessage(
          `Failed to update ${selctediZone.label} cordinates (${err})`
        );
      });
  };

  useEffect(() => {
    httpService
      .get("/v1/izone")
      .then((rs) => {
        const { data } = rs;
        setizonesAvailables([
          ...data.map((a) => ({ label: a.name, value: a._id })),
        ]);
      })
      .catch((er) => { });
  }, []);

  return (
    <IZoneCU
      close={props.close}
      forceShow={props.forceShow}
      proccess={() => {
        updateZone();
      }}
      fields={[]}
      type="OK"
      title={`Update Zone Locations`}
      titleComponent={<></>}
      multipleSelect={
        <>
          <IZoneNew />
          <GenericSelectForm
            title="iZones"
            handleChange={(e) => {
              setselctediZone({ ...e });
            }}
            options={izonesAvailables}
          />
        </>
      }
    />
  );
};

export const UpdaAccessLocations = (props) => {
  const [selctediZone, setselctediZone] = useState({});
  const [izonesAvailables, setizonesAvailables] = useState();

  const updateZone = () => {
    alert(JSON.stringify(props));
    httpService
      .put(`/v1/LocationsAccess/${selctediZone.value}/cordinates`, {
        floor: props.currentSelectedBuilding._id,
        cordinates: props.forceShow,
      })
      .then((r) => {
        successMessage(
          `${selctediZone.label} cordinates have been successfully updated`
        );
      })
      .catch((err) => {
        errorMessage(
          `Failed to update ${selctediZone.label} cordinates (${err})`
        );
      });
  };

  useEffect(() => {
    httpService
      .get("/v1/LocationsAccess")
      .then((rs) => {
        const { data } = rs;
        setizonesAvailables([
          ...data.map((a) => ({ label: a.name, value: a._id })),
        ]);
      })
      .catch((er) => { });
  }, []);

  return (
    <IZoneCU
      close={props.close}
      forceShow={props.forceShow}
      proccess={() => {
        updateZone();
      }}
      fields={[]}
      type="OK"
      title={`Update Locations`}
      titleComponent={<></>}
      multipleSelect={
        <>
          <GenericSelectForm
            title="Access Lcoations"
            handleChange={(e) => {
              setselctediZone({ ...e });
            }}
            options={izonesAvailables}
          />
        </>
      }
    />
  );
};

export const IZoneUpdator = (props) => {
  const fields = [
    { name: "name", title: "iZone Name" },
    { name: "floor", title: "Floor Name" },
  ];

  const updateZone = (id) => {
    httpService
      .put(`/v1/izone/${id}`, {
        ...izoneInfo,
        entranceLocations: selectedEntrances.map((m) => m.value),
        allowedPreLocations: selectedPreLocations.map((m) => m.value),
      })
      .then((res) => {
        const { data } = res;
        successMessage(`${izoneInfo.name} has been udpated successfully.`);
        loadiZones();
        loadLocations();
      })
      .catch((err) => {
        const { response } = err;
        errorMessage(response.data);
      });
  };

  const updateInfo = (name, value) => {
    setizoneInfo((old) => ({ ...old, [name]: value }));
  };

  const loadiZones = () => {
    httpService
      .get(`/v1/izone`)
      .then((res) => {
        const { data } = res;
        setzonesInfo([...data]);
      })
      .catch((error) => { });
  };

  const loadLocations = () => {
    httpService
      .get(`/v1/LocationsAccess/lite`)
      .then((res) => {
        const { data } = res;
        setlocations([...data.map((v) => ({ value: v._id, label: v.name }))]);
      })
      .catch((err) => { });
  };

  const loadiZoneInfo = (id) => {
    httpService
      .get(`/v1/izone/${id}`)
      .then((res) => {
        const { data } = res;
        setizoneInfo({
          name: data.name,
          status: data.status,
          floor: data.floor,
        });
        setselectedEntrances([
          ...data.entranceLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
        setselectedPreLocations([
          ...data.allowedPreLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
      })
      .catch((err) => { });
  };

  const clearInput = () => {
    setselectedEntrances([]);
    setselectedPreLocations([]);
  };

  useEffect(() => {
    loadiZones();
    loadLocations();
  }, []);

  const [izoneInfo, setizoneInfo] = useState({ name: "" });

  const [zonesInfo, setzonesInfo] = useState();

  const [locations, setlocations] = useState([]);
  const [selectedEntrances, setselectedEntrances] = useState([]);
  const [selectedPreLocations, setselectedPreLocations] = useState([]);

  return (
    <>
      <IZoneCU
        data={izoneInfo}
        proccess={() => updateZone(props._id)}
        handleChange={updateInfo}
        load={() => {
          clearInput();
          loadiZoneInfo(props._id.toString());
        }}
        fields={fields}
        type="Update"
        title={`Update ${props.name} details.`}
        titleComponent={props.titleComponent}
        multipleSelect={
          <>
            <Form.Label>Entrances</Form.Label>
            {selectedEntrances && (
              <MultiSelect
                options={locations}
                value={selectedEntrances}
                onChange={setselectedEntrances}
                labelledBy="Select"
              />
            )}

            <Form.Label>Approved Pre-locations</Form.Label>
            {selectedPreLocations && (
              <MultiSelect
                options={locations}
                value={selectedPreLocations}
                onChange={setselectedPreLocations}
                labelledBy="Select"
              />
            )}
            <GenericSelectForm
              value={{ value: izoneInfo?.status, label: izoneInfo?.status }}
              title="Status"
              handleChange={(e) =>
                setizoneInfo((oldValue) => ({
                  ...oldValue,
                  ["status"]: e.value,
                }))
              }
              options={[
                { value: "Locked", label: "Locked" },
                { value: "Unlocked", label: "Unlocked" },
              ]}
            />
          </>
        }
      />
    </>
  );
};

export const IZoneNew = (props) => {
  const fields = [
    { name: "name", title: "iZone Name" },
    { name: "floor", title: "Floor Name" },
  ];

  const updateZone = () => {
    httpService
      .post(`/v1/izone`, {
        ...izoneInfo,
        entranceLocations: selectedEntrances.map((m) => m.value),
        allowedPreLocations: selectedPreLocations.map((m) => m.value),
      })
      .then((res) => {
        const { data } = res;
        successMessage(`${izoneInfo.name} has been udpated successfully.`);
        loadiZones();
        loadLocations();

        if (props.reload) props.reload();
      })
      .catch((err) => {
        const { response } = err;
        errorMessage(response.data);
      });
  };

  const updateInfo = (name, value) => {
    setizoneInfo((old) => ({ ...old, [name]: value }));
  };

  const loadiZones = () => {
    httpService
      .get(`/v1/izone`)
      .then((res) => {
        const { data } = res;
        setzonesInfo([...data]);
      })
      .catch((error) => { });
  };

  const loadLocations = () => {
    httpService
      .get(`/v1/LocationsAccess/lite`)
      .then((res) => {
        const { data } = res;
        setlocations([...data.map((v) => ({ value: v._id, label: v.name }))]);
      })
      .catch((err) => { });
  };

  const loadiZoneInfo = (id) => {
    httpService
      .get(`/v1/izone/${id}`)
      .then((res) => {
        const { data } = res;
        setizoneInfo({
          name: data.name,
          status: data.status,
          floor: data.floor,
        });
        setselectedEntrances([
          ...data.entranceLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
        setselectedPreLocations([
          ...data.allowedPreLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
      })
      .catch((err) => { });
  };

  const clearInput = () => {
    setselectedEntrances([]);
    setselectedPreLocations([]);
  };

  useEffect(() => {
    loadiZones();
    loadLocations();
  }, []);

  const [izoneInfo, setizoneInfo] = useState({ name: "" });

  const [zonesInfo, setzonesInfo] = useState();

  const [locations, setlocations] = useState([]);
  const [selectedEntrances, setselectedEntrances] = useState([]);
  const [selectedPreLocations, setselectedPreLocations] = useState([]);

  return (
    <>
      <IZoneCU
        data={izoneInfo}
        proccess={() => updateZone()}
        handleChange={updateInfo}
        load={() => {
          clearInput();
        }}
        fields={fields}
        type="Create"
        title={`Create New iZone`}
        titleComponent={props.titleComponent}
        multipleSelect={
          <>
            <Form.Label>Entrances</Form.Label>
            {selectedEntrances && (
              <MultiSelect
                options={locations}
                value={selectedEntrances}
                onChange={setselectedEntrances}
                labelledBy="Select"
              />
            )}

            <Form.Label>Approved Pre-locations</Form.Label>
            {selectedPreLocations && (
              <MultiSelect
                options={locations}
                value={selectedPreLocations}
                onChange={setselectedPreLocations}
                labelledBy="Select"
              />
            )}
            <GenericSelectForm
              value={{ value: izoneInfo?.status, label: izoneInfo?.status }}
              title="Status"
              handleChange={(e) =>
                setizoneInfo((oldValue) => ({
                  ...oldValue,
                  ["status"]: e.value,
                }))
              }
              options={[
                { value: "Locked", label: "Locked" },
                { value: "Unlocked", label: "Unlocked" },
              ]}
            />
          </>
        }
      />
    </>
  );
};

export default function IZones() {
  const fields = [
    { name: "name", title: "iZone Name" },
    { name: "floor", title: "Floor Name" },
  ];

  const izonePeopleColums = [
    {
      sortable: true,
      name: "Person",
      selector: (row) => row?.person?._id,
      grow: 2,
      cell: (row) => (
        <>
          {row?.picture && (
            <>
              <Image
                roundedCircle={true}
                fluid={true}
                style={{ width: 100, height: 100, margin: 2 }}
                src={`${row?.picture}`}
              />
              <UpdatePersonV3
                id={row?.person?._id}
                label={`${row?.name} ${row?.surname}`}
              />{" "}
            </>
          )}
        </>
      ),
    },
    {
      sortable: true,
      name: "Last activy",
      grow: 2,
      selector: (row) => row.lastActivityTime,
      cell: (row) => (
        <>{moment(row.lastActivityTime).format("MMMM Do YYYY, h:mm:ss a")}</>
      ),
    },
  ];

  const colums = [
    {
      sortable: true,
      name: "name",
      selector: (row) => row?.name,
      cell: (row) => <p>{row.name}</p>,
    },
    {
      sortable: true,
      name: "Entrances",
      cell: (row) => (
        <>
          {row.entranceLocations.map((a) => (
            <>
              <br></br> <DoorOpen /> {a.name}
            </>
          ))}
        </>
      ),
    },
    {
      sortable: true,
      name: "Allowed Pre-locations",
      cell: (row) => (
        <p>
          {row.allowedPreLocations.map((a) => (
            <>
              {" "}
              <>
                <DoorOpen /> {a.name}
              </>
            </>
          ))}
        </p>
      ),
    },
    {
      sortable: true,
      name: "",
      cell: (row) => (
        <>
          <IZoneCU
            data={izoneInfo}
            proccess={() => updateZone(row._id)}
            handleChange={updateInfo}
            load={() => {
              clearInput();
              loadiZoneInfo(row._id.toString());
            }}
            fields={fields}
            type="Update"
            title={`Update ${row.name} details.`}
            titleComponent={
              <Button variant="dark" size="sm">
                <Info />
              </Button>
            }
            multipleSelect={
              <>
                <Form.Label>Entrances</Form.Label>
                {selectedEntrances && (
                  <MultiSelect
                    options={locations}
                    value={selectedEntrances}
                    onChange={setselectedEntrances}
                    labelledBy="Select"
                  />
                )}

                <Form.Label>Approved Pre-locations</Form.Label>
                {selectedPreLocations && (
                  <MultiSelect
                    options={locations}
                    value={selectedPreLocations}
                    onChange={setselectedPreLocations}
                    labelledBy="Select"
                  />
                )}
                <GenericSelectForm
                  value={{ value: izoneInfo?.status, label: izoneInfo?.status }}
                  title="Status"
                  handleChange={(e) =>
                    setizoneInfo((oldValue) => ({
                      ...oldValue,
                      ["status"]: e.value,
                    }))
                  }
                  options={[
                    { value: "Locked", label: "Locked" },
                    { value: "Unlocked", label: "Unlocked" },
                  ]}
                />
              </>
            }
          />
          <IZoneCU
            data={izoneInfo}
            proccess={() => updateZone(row._id)}
            handleChange={updateZone}
            load={() => {
              loadPeopleiZones(row._id);
            }}
            fields={[]}
            type="OK"
            title={`People in ${row.name}`}
            titleComponent={
              <Button
                title={`People in ${row.name}.`}
                style={{ margin: 2 }}
                variant="warning"
                size="sm"
              >
                <People />
              </Button>
            }
            multipleSelect={
              <>
                <DataTable
                  pagination={true}
                  paginationPerPage={10}
                  columns={izonePeopleColums}
                  data={peopleInSelectedZone}
                />
              </>
            }
          />
          <ListOfPeopleV2
            peopleInSelectedZone={peopleInSelectedZone}
            row={row}
          />
          <Button
            style={{ margin: 2 }}
            onClick={(e) => deleteZone(row.name, row._id.toString())}
            variant="danger"
            size="sm"
          >
            <Eraser />
          </Button>
        </>
      ),
    },
  ];

  const createiZone = () => {
    httpService
      .post(`/v1/izone`, {
        ...izoneInfo,
        entranceLocations: selectedEntrances.map((m) => m.value),
        allowedPreLocations: selectedPreLocations.map((m) => m.value),
      })
      .then((res) => {
        const { data } = res;
        successMessage(`${data.name} has been successfully created.`);
        loadiZones();
        loadLocations();
      })
      .catch((err) => {
        const { response } = err;
        errorMessage(response.data);
      });
  };

  const updateZone = (id) => {
    httpService
      .put(`/v1/izone/${id}`, {
        ...izoneInfo,
        entranceLocations: selectedEntrances.map((m) => m.value),
        allowedPreLocations: selectedPreLocations.map((m) => m.value),
      })
      .then((res) => {
        const { data } = res;
        successMessage(`${izoneInfo.name} has been udpated successfully.`);
        loadiZones();
        loadLocations();
      })
      .catch((err) => {
        const { response } = err;
        errorMessage(response.data);
      });
  };

  const updateInfo = (name, value) => {
    setizoneInfo((old) => ({ ...old, [name]: value }));
  };

  const loadiZones = () => {
    httpService
      .get(`/v1/izone`)
      .then((res) => {
        const { data } = res;
        setzonesInfo([...data]);
      })
      .catch((error) => { });
  };

  const loadPeopleiZones = (id) => {
    httpService
      .get(`/v1/izone/${id}/people`)
      .then((res) => {
        const { data } = res;
        setpeopleInSelectedZone([...data.people]);
      })
      .catch((error) => { });
  };

  const deleteZone = (name, id) => {
    httpService
      .delete(`/v1/izone/${id}`)
      .then((res) => {
        successMessage(`${name} has been successfully Delete.`);
        loadiZones();
        loadLocations();
      })
      .catch((error) => { });
  };

  const loadLocations = () => {
    httpService
      .get(`/v1/LocationsAccess/lite`)
      .then((res) => {
        const { data } = res;
        setlocations([...data.map((v) => ({ value: v._id, label: v.name }))]);
      })
      .catch((err) => { });
  };

  const loadiZoneInfo = (id) => {
    httpService
      .get(`/v1/izone/${id}`)
      .then((res) => {
        const { data } = res;
        setizoneInfo({
          name: data.name,
          status: data.status,
          floor: data.floor,
        });
        setselectedEntrances([
          ...data.entranceLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
        setselectedPreLocations([
          ...data.allowedPreLocations.map((e) => ({
            label: e.name,
            value: e._id,
          })),
        ]);
      })
      .catch((err) => { });
  };

  const loadFloors = () => {
    httpService
      .get("/v1/building")
      .then((res) => {
        const { data } = res;
        setfloors(
          [...data].map((a) => ({
            value: a._id,
            label: `${a.name}/${a.description}`,
          }))
        );
      })
      .catch((err) => { });
  };

  const clearInput = () => {
    setselectedEntrances([]);
    setselectedPreLocations([]);
  };

  useEffect(() => {
    loadiZones();
    loadLocations();
    loadFloors();
  }, []);

  const [izoneInfo, setizoneInfo] = useState({ name: "" });

  const [zonesInfo, setzonesInfo] = useState();

  const [locations, setlocations] = useState([]);
  const [selectedEntrances, setselectedEntrances] = useState([]);
  const [selectedPreLocations, setselectedPreLocations] = useState([]);

  const [floors, setfloors] = useState([]);

  const [peopleInSelectedZone, setpeopleInSelectedZone] = useState();

  return (
    <>
      <Header />
      <HeaderTitle name="iZones" />

      <Container fluid={false}>
        <IZoneCRUD
          reload={loadiZones}
          type={"new"}
          locations={locations}
          floors={floors}
          buttonColor={"dark"}
          icon="+"
          title={"New iZone"}
        />
        {/* <IZoneCU
          load={() => clearInput()}
          proccess={createiZone}
          handleChange={updateInfo}
          fields={fields}
          type="Create"
          title={`Create new iZone.`}
          titleComponent={
            <Button variant="dark" size="sm">
              <Plus />
            </Button>
          }
          multipleSelect={
            <>
              <Form.Label>Entrances</Form.Label>
              <MultiSelect
                options={locations}
                value={selectedEntrances}
                onChange={setselectedEntrances}
                labelledBy="Select"
              />

              <Form.Label>Approved Pre-locations</Form.Label>
              <MultiSelect
                options={locations}
                value={selectedPreLocations}
                onChange={setselectedPreLocations}
                labelledBy="Select"
              />
              <GenericSelectForm
                value={{ value: izoneInfo?.status, label: izoneInfo?.status }}
                title="Status"
                handleChange={(e) =>
                  setizoneInfo((oldValue) => ({
                    ...oldValue,
                    ["status"]: e.value,
                  }))
                }
                options={[
                  { value: "Locked", label: "Locked" },
                  { value: "Unlocked", label: "Unlocked" },
                ]}
              />
            </>
          }
        /> */}

        <DataTable
          pagination={true}
          paginationPerPage={10}
          columns={colums}
          data={zonesInfo}
        />
        <LoadingComponents isLoading={zonesInfo == null} />
      </Container>
    </>
  );
}

function IZoneCRUD({
  reload,
  title,
  icon,
  floors,
  type,
  buttonColor,
  locations,
}) {
  const [displayModal, setdisplayModal] = useState(false);
  const [izoneDetails, setizoneDetails] = useState({});

  const createNewiZone = () => {
    httpService
      .post(`/v1/izone`, {
        name: izoneDetails.name,
        entranceLocations: izoneDetails.entranceLocations,
        allowedPreLocations: izoneDetails.allowedPreLocations,
        floor: izoneDetails.floor,
      })
      .then((res) => {
        setdisplayModal(false);
        successMessage(`iZone ${izoneDetails.name} was created succssfully !`);
        if (reload) reload();
      })
      .catch((error) => {
        {
          errorMessage(
            `Failed to creare iZone (${error?.response?.data || error}) !`
          );
        }
      });
  };

  const generateButton = () => {
    if (type === "new")
      return (
        <>
          <Button
            style={{ margin: 2 }}
            size="sm"
            onClick={(e) => {
              createNewiZone();
            }}
            variant="success"
            key="back"
          >
            Create
          </Button>
        </>
      );
    else return <></>;
  };

  return (
    <>
      {!displayModal && (
        <Button
          style={{ margin: 2 }}
          onClick={(e) => setdisplayModal(true)}
          variant={buttonColor || "dark"}
          size="sm"
        >
          {icon || "+"}
        </Button>
      )}
      {displayModal && (
        <Modal
          onCancel={() => setdisplayModal(false)}
          footer={[
            <Button
              style={{ margin: 2 }}
              size="sm"
              onClick={(e) => {
                setdisplayModal(false);
                setizoneDetails({});
              }}
              variant="dark"
              key="back"
            >
              Close
            </Button>,
            generateButton(),
          ]}
          forceRender={true}
          okType="success"
          width={500}
          title={title}
          visible={displayModal}
          onOk={() => { }}
        >
          <FormAnt
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 20 }}
            layout="horizontal"
          >
            <FormAnt.Item label="Name">
              <Input
                onChange={(e) =>
                  setizoneDetails((old) => ({ ...old, name: e.target.value }))
                }
              />
            </FormAnt.Item>
            <FormAnt.Item label="Status">
              <Radio.Group
                defaultValue={"unlocked"}
                onChange={(e) =>
                  setizoneDetails((old) => ({ ...old, status: e.target.value }))
                }
              >
                <Radio.Button value="unlocked" style={{ color: "green" }}>
                  Unlocked
                </Radio.Button>
                <Radio.Button value="locked" style={{ color: "red" }}>
                  Locked
                </Radio.Button>
              </Radio.Group>
            </FormAnt.Item>
            <FormAnt.Item label="Floor">
              <Select
                onChange={(e) =>
                  setizoneDetails((old) => ({ ...old, floor: e }))
                }
                value={izoneDetails?.floor}
              >
                {floors?.map((floor) => (
                  <>
                    <Select.Option value={floor.value}>
                      {floor.label}
                    </Select.Option>
                  </>
                ))}
              </Select>
            </FormAnt.Item>
            <FormAnt.Item label="Entrances">
              <Select
                mode="tags"
                onChange={(e) =>
                  setizoneDetails((old) => ({ ...old, entranceLocations: e }))
                }
                value={izoneDetails?.entranceLocations}
              >
                {locations?.map((location) => (
                  <>
                    <Select.Option value={location.value}>
                      {location.label}
                    </Select.Option>
                  </>
                ))}
              </Select>
            </FormAnt.Item>
            <FormAnt.Item label="Pre-Locations">
              <Select
                mode="tags"
                onChange={(e) =>
                  setizoneDetails((old) => ({ ...old, allowedPreLocations: e }))
                }
                value={izoneDetails?.allowedPreLocations}
              >
                {locations?.map((location) => (
                  <>
                    <Select.Option value={location.value}>
                      {location.label}
                    </Select.Option>
                  </>
                ))}
              </Select>
            </FormAnt.Item>
          </FormAnt>
        </Modal>
      )}
    </>
  );
}
